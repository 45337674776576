import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import BuildIcon from '@mui/icons-material/Build';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import sourcelistService from '../../service/sourcelist.service';

const renderTree = (node, parentItemCode = '') => {
  const baseId = parentItemCode ? `${parentItemCode}-${node.itemCode}` : node.itemCode;

  return (
    <TreeItem
      key={baseId}
      itemId={baseId}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PostAddIcon style={{ marginRight: 5 }} fontSize="small" />
          {node.item.itemName}
        </div>
      }
    >
      {node.sourceListOperation?.map((o) => (
        <TreeItem
          key={`${baseId}-${o.operation.operationName}`}
          itemId={`${baseId}-${o.operation.operationName}`}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BuildIcon style={{ marginRight: 5 }} fontSize="small" />
              {o.operation.operationName}
            </div>
          }
        />
      ))}
      {node.subParts?.map((subPart) => renderTree(subPart, baseId))}
    </TreeItem>
  );
};

const SourceListTree = ({ itemCode }) => {
  const [treeData, setTreeData] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    setExpandedItems((prevExpanded) => {
      if (prevExpanded.length === 0 && treeData) {
        // Ha a fa nem volt kibővítve, akkor az összes elem kinyitása
        return getAllItemIds(treeData);
      } else {
        // Ha már kinyitották, akkor bezárni őket
        return [];
      }
    });
  };

  const getAllItemIds = (node, ids = []) => {
    if (!node) return ids;
    ids.push(node.itemCode);
    node.subParts?.forEach((subPart) => getAllItemIds(subPart, ids));
    return ids;
  };

  const getTreeData = () => {
    sourcelistService.getTreeData(itemCode).then((data) => {
      setTreeData(data);
      // Beállítjuk az összes elem kinyitásához szükséges itemCode-okat, ha van adat
      setExpandedItems(getAllItemIds(data));
    });
  };

  useEffect(() => {
    if (itemCode) {
      getTreeData();
    } else {
      setTreeData(null);
    }
  }, [itemCode]);

  return (
    <Stack spacing={0}>
      <div>
        <Button onClick={handleExpandClick}>{expandedItems.length === 0 ? 'Kinyit' : 'Becsuk'}</Button>
      </div>
      <Box sx={{ minHeight: 180, minWidth: 250 }}>
        {treeData && (
          <SimpleTreeView expandedItems={expandedItems} onExpandedItemsChange={handleExpandedItemsChange}>
            {renderTree(treeData)}
          </SimpleTreeView>
        )}
      </Box>
    </Stack>
  );
};

export default SourceListTree;
