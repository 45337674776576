import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import { FormButtonPanelUI, FormHeaderUI, FormBase } from '../Interface';
import exchangeRateService from '../../service/exchangeRate.service';
import ExchangeRateFields from './ExchangeRateFields';
import ExchangeRateReports from './ExchangeRateReports';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';

export const ExchangeRatePage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    currency: [],
  });

  const def = {
    rateDate: dayjs(),
    currencyCode: null,
    rate: '',
  };

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .currencyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          currency: resp[0],
        };
        setAutocompleteData(aData);

        const acOptions = {
          currency: generateAutocompleteItems(aData.currency, 'isoCode', 'code'),
        };
        setAutoCompleteOptions(acOptions);
        setLoading(false);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
    setDefaultSelectedValues(def);
    initDefaultValues();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('exchangeRate');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    rateDate: Yup.string().required(),
    currencyCode: Yup.string().required(),
    rate: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { rateDate, currencyCode, rate } = selectedValues;

    setInitValues({
      rateDate: dayjs(rateDate),
      currencyCode,
      rate,
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      rateDate: dayjs(values.rateDate).toISOString(),
      rate: parseInt(values.rate),
      updatedBy: user.userId,
    };

    exchangeRateService.updateItem(updData).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      rateDate: dayjs(values.rateDate).toISOString(),
      rate: parseInt(values.rate),
      createdBy: user.userId,
    };

    exchangeRateService
      .createItem(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);
        setRowSelectionModel(data.itemCode);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  return (
    <FormBase
      name="ExchangeRate"
      leftPanelContent={
        <>
          <div className="h-full overflow-auto custom-scrollbar">
            <FormHeaderUI
              addClick={() => {
                setSelectedValues(defaultSelectedValues);
                setViewField(false);
                setAddField(false);
              }}
              addDisabled={!viewField || !canEdit}
              modClick={() => {
                setViewField(false);
                setEditButtonvisible(false);
              }}
              modDisabled={!viewField || operatorsDisabled || !canEdit}
              copyClick={() => {
                setViewField(false);
                setAddField(false);
              }}
              copyDisabled={!viewField || operatorsDisabled || !canEdit}
            />

            <Formik
              initialValues={initValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ resetForm }) => (
                <Form>
                  <ExchangeRateFields
                    viewField={viewField}
                    selectedValues={selectedValues}
                    addField={addField}
                    autocompleteOptions={autocompleteOptions}
                  />

                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                    disabled={editButtonVisible && addField}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </>
      }
      rightPanelContent={
        <>
          {!loading && (
            <ExchangeRateReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
              autocompleteOptions={autocompleteOptions}
            />
          )}
        </>
      }
    />
  );
};

export default ExchangeRatePage;
