import React, { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import itemGroupService from '../../service/itemGroup.service';
import { ItemGroupTypeList, StockArticleTypeList } from '../../config/itemGroup';

export const ItemGroupReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autoCompleteOptions,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    itemGroupService.getRows(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'name',
        headerName: 'Megnevezés',
        type: 'string',
      },
      {
        field: 'quantityUnitId',
        headerName: 'Alapértelmezett mennyiségi egység',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.quantityUnit?.quantityUnitName;
        },
        valueOptions: autoCompleteOptions.quantityUnit,
      },
      {
        field: 'type',
        headerName: 'Típus',
        type: 'singleSelect',
        valueOptions: ItemGroupTypeList,
      },
      {
        field: 'stockItemType',
        headerName: 'Készletcikk fajta',
        type: 'singleSelect',
        valueOptions: StockArticleTypeList,
      },
      {
        field: 'inPurchase',
        headerName: 'Beszerzésben részt vesz',
        type: 'boolean',
      },
      {
        field: 'inSales',
        headerName: 'Értékesítésben részt vesz',
        type: 'boolean',
      },
      {
        field: 'groupPrefix',
        headerName: 'Cikkcsoport prefix',
        type: 'string',
      },
    ]);
  }, [autoCompleteOptions]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.itemGroup}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};
