import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';
import Yup from '../../utils/yup';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useUsers from '../../context/UsersContext';
import useMenus from '../../context/MenuContext';
import ConfigPageFields from './ConfigPageFields';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';
import configService from '../../service/config.service';
import { FormButtonPanelUI, FormHeaderUI } from '../Interface';

export const ConfigPage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [formData, setFormData] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [defaultFormData, setDefaultFormData] = useState({});
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    country: [],
    currency: [],
    warehouse: [],
  });

  const def = {
    name: '',
    shortName: '',
    nameInPrint: '',
    countryCode: '',
    province: '',
    postCode: '',
    city: '',
    streetAddress: '',
    website: '',
    email: '',
    phoneNumber: '',
    GLN: '',
    hunTaxNumber: '',
    euTaxNumber: '',
    decimalPlaces: '',
    currencyCode: '',
    defaultWarehouse: '',
    other: '',
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
    setDefaultFormData(def);
    initDefaultValues();
    getConfig();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('config');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      if (!isEqual(defaultFormData, formData)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [formData]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    shortName: Yup.string().nullable(),
    nameInPrint: Yup.string().nullable(),
    countryCode: Yup.string().nullable(),
    province: Yup.string().nullable(),
    postCode: Yup.string().nullable(),
    city: Yup.string().nullable(),
    streetAddress: Yup.string().nullable(),
    website: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
    phoneNumber: Yup.string().nullable(),
    GLN: Yup.string().nullable(),
    hunTaxNumber: Yup.string().nullable(),
    euTaxNumber: Yup.string().nullable(),
    decimalPlaces: Yup.number().nullable().max(5),
    currencyCode: Yup.string().nullable(),
    defaultWarehouse: Yup.string().nullable(),
    other: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const {
      name,
      shortName,
      nameInPrint,
      countryCode,
      province,
      postCode,
      city,
      streetAddress,
      website,
      email,
      phoneNumber,
      GLN,
      hunTaxNumber,
      euTaxNumber,
      decimalPlaces,
      currencyCode,
      defaultWarehouse,
      other,
    } = formData;

    setInitValues({
      name,
      shortName,
      nameInPrint,
      countryCode,
      province,
      postCode,
      city,
      streetAddress,
      website,
      email,
      phoneNumber,
      GLN,
      hunTaxNumber,
      euTaxNumber,
      decimalPlaces,
      currencyCode,
      defaultWarehouse,
      other,
    });
  };

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .countryToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .currencyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .warehouseToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          country: resp[0],
          currency: resp[1],
          warehouse: resp[2],
        };

        const acOptions = {
          country: generateAutocompleteItems(aData.country, 'name', 'code'),
          currency: generateAutocompleteItems(aData.currency, 'code', 'code'),
          warehouse: generateAutocompleteItems(aData.warehouse, 'warehouseCode', 'warehouseCode'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
      decimalPlaces: parseInt(values.decimalPlaces),
    };

    configService
      .rowData(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setFormData(data);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const getConfig = () => {
    configService.getData().then((data) => {
      setFormData(data);
    });
  };

  return (
    <div className="custom-scrollbar">
      <FormHeaderUI
        modClick={() => {
          setViewField(false);
          setEditButtonvisible(false);
        }}
        modDisabled={!viewField || operatorsDisabled || !canEdit}
      />

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleInsertObject}
        enableReinitialize
      >
        {({ resetForm }) => (
          <Form>
            <ConfigPageFields viewField={viewField} formData={formData} autoCompleteOptions={autoCompleteOptions} />

            <FormButtonPanelUI
              onCancelClick={() => {
                setViewField(true);
                setEditButtonvisible(true);

                resetForm();
              }}
              disabled={editButtonVisible}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
